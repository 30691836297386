import React from 'react'

function NotFound() {
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        <h1 style={{fontSize:'30px',color:'white'}}>PAGE NOT FOUND</h1>
    </div>
  )
}

export default NotFound